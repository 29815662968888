import { clsx } from 'clsx';
import * as React from 'react';
import { AnchorOrLink } from '~/utils/misc.tsx';

interface ButtonProps {
  size?: 'medium' | 'large';
  rounded?: 'lg' | 'sm' | 'full';
  variant?: 'primary' | 'secondary' | 'outline';
  children: React.ReactNode | React.ReactNode[];
}

function getClassName({
  className,
  rounded,
  variant
}: {
  className?: string;
  rounded: ButtonProps['rounded'];
  variant: ButtonProps['variant'];
}) {
  return clsx(
    'group relative cursor-pointer overflow-hidden inline-flex items-center justify-center font-medium opacity-100 disabled:opacity-50 transition',
    `rounded-${rounded}`,
    {
      'border-2 border-grey-100 text-grey-100': variant === 'outline'
    },
    className
  );
}

function PrimaryButton({ children, size }: Pick<ButtonProps, 'children' | 'variant' | 'size'>) {
  return (
    <span
      className={clsx('rounded-md bg-red-400 font-medium text-white hover:bg-red-500', {
        'px-8 py-3 text-xs md:text-sm lg:text-lg': size === 'large',
        'px-6 py-2 text-sm': size !== 'large'
      })}
    >
      {children}
    </span>
  );
}

function SecondaryButton({ children, size }: Pick<ButtonProps, 'children' | 'variant' | 'size'>) {
  return (
    <span
      className={clsx('rounded-md bg-gray-100  font-medium text-red-400 hover:bg-gray-200 dark:text-gray-800', {
        'px-8 py-3 text-lg': size === 'large',
        'px-6 py-2 text-sm': size !== 'large'
      })}
    >
      {children}
    </span>
  );
}

function OutlineInnerButton({ children, size }: Pick<ButtonProps, 'children' | 'size'>) {
  return (
    <span
      className={clsx('rounded-md text-white ease-in-out hover:bg-gray-100 group-hover:text-black', {
        'text-lg font-medium': size === 'large',
        'text-sm font-medium': size !== 'large'
      })}
    >
      {children}
    </span>
  );
}

function ButtonInner({ children, variant, size }: Pick<ButtonProps, 'children' | 'variant' | 'size' | 'rounded'>) {
  return (
    <>
      {variant === 'primary' ? <PrimaryButton size={size}>{children}</PrimaryButton> : null}
      {variant === 'secondary' ? <SecondaryButton size={size}>{children}</SecondaryButton> : null}
      {variant === 'outline' ? <OutlineInnerButton size={size}>{children}</OutlineInnerButton> : null}
    </>
  );
}

function Button({
  children,
  variant = 'primary',
  size = 'large',
  rounded = 'lg',
  className,
  ...buttonProps
}: ButtonProps & JSX.IntrinsicElements['button']): JSX.Element {
  return (
    <button {...buttonProps} className={getClassName({ className, rounded, variant })}>
      <ButtonInner variant={variant} size={size} rounded={rounded}>
        {children}
      </ButtonInner>
    </button>
  );
}

const ButtonLink = React.forwardRef<HTMLAnchorElement, React.ComponentPropsWithRef<typeof AnchorOrLink> & ButtonProps>(
  function ButtonLink({ children, size = 'large', variant = 'primary', rounded = 'lg', className, ...rest }, ref) {
    return (
      <AnchorOrLink ref={ref} className={getClassName({ className, rounded, variant })} {...rest}>
        <ButtonInner variant={variant} size={size} rounded={rounded}>
          {children}
        </ButtonInner>
      </AnchorOrLink>
    );
  }
);

function LinkButton({
  className,
  underlined,
  ...buttonProps
}: { underlined?: boolean } & JSX.IntrinsicElements['button']): JSX.Element {
  return (
    <button
      {...buttonProps}
      className={clsx(
        className,
        underlined ? 'underlined whitespace-nowrap focus:outline-none' : 'underline',
        'text-primary inline-block'
      )}
    />
  );
}

export { Button, ButtonLink, LinkButton };
